@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&display=swap');

.header {
  font-family: "Martian Mono", monospace;
  color: white;
  background-color: #1f1fa3;
  display: flex;
  width: 100%;
  height: 100%;
}

.header ul {
  vertical-align: middle;
  display: inline-block;
  margin: 10px;
  padding: 0;
  overflow: hidden;
}

.header li {
  list-style-type: none;
}

.header .brand {
  position: absolute;
  right: 10px;
  top: 30px;
}

.header .brand a {
  text-decoration: none;
  color: white;
  :hover {
    color: orange;
  }
}

.body {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
}
