.pinmux-settings-variant-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.pinmux-settings-variant-card {
  flex: 0 1 24%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  margin: 1em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.pinmux-settings-variant-card-selected {
  border-color: #f9ce6e;
  border-width: 2px;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.2);
}

.pinmux-settings-variant-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  text-decoration: underline;
}

.pinmux-settings-variant-card img {
  padding: 1em 2em;
  align-content: center;
  height: 16em;
}

.pinmux-settings-variant-card p {
  padding: 1em;
}
