.device-tree {
  display: flex;
  flex: 2;
  min-width: 300px;
}

.device-tree textarea {
  tab-size: 3;
  min-height: 200px;
  margin: 10px 40px;
  display: block;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 12px;
  resize: none;
}
