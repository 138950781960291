.board-container {
  flex: 3;
  position: relative;
  width: 100%;
  text-align: center;
}

.pin-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.pin-overlay .header-connector {
  position: absolute;
  top: 0;
  left: 0;
}
 
.pin-overlay .pin-header-title span:hover {
  border-style: double;
  color: DarkSlateGray;
}

.pin-overlay .pin-header-title span {
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
}

.pin-overlay .pin-header-title-selected span {
  background-color: AliceBlue;
}

.pin-overlay .pin-header-title-hidden span {
  border-color: DarkGrey;
  background-color: DimGrey;
}

.pin-overlay table, .pin-overlay td { 
  white-space: nowrap;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}

.pin-overlay td {
  font-size: small;
  user-select: none;
}

.pin-overlay td .pin-desc {
  cursor: pointer;
}

.pin-overlay td .pin-desc-clickable:hover {
  border-style: double;
  color: DarkSlateGray;
}

h3 {
  text-align: center;
}

.pin-row {
  flex-direction: row;
  align-items: stretch;
  font-size: x-small;
}

.pinmux-board-header-pin {
  z-index: 5;
  aspect-ratio: 1/1;
  display: inline-block;
}

.pin-desc {
  color: black;
  background-color: GhostWhite;
  padding: 0px 2px;
  margin: 2px;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.GND {
  color: AliceBlue;
  background-color: DarkSlateGray;
}

.VDD {
  color: black;
  background-color: IndianRed;
}

.RST {
  color: black;
  background-color: GainsBoro;
}

.BLK {
  color: DarkSlateGray;
  background-color: white;
}

.gpio-selected {
  border-color: red;
  background-color: lightgrey;
}

.uart {
  background-color: SandyBrown;
}

.uart-selected {
  border-color: red;
  background-color: Salmon;
}

.spi {
  background-color: SkyBlue;
}

.spi-selected {
  border-color: red;
  background-color: SteelBlue;
}

.i2c {
  background-color: MediumSeaGreen;
}

.i2c-selected {
  border-color: red;
  background-color: SeaGreen;
}
